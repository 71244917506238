import momentTz from 'moment-timezone';
import { nanoid } from 'nanoid';

import { defaultInstallationMeta } from 'RioRedux/devices/reducer';

import {
  AddDevice,
  AddDeviceResponse,
  APIAddDevice,
  APIAddDeviceResponse,
  APIDeployment,
  APIDevice,
  APIDeviceDetails,
  APIDeviceLog,
  APIDeviceLogUrl,
  APILogDetail,
  APIMetric,
  APIMetricErrorLog,
  APIProgress,
  APIResTopics,
  APIResult,
  APIRosbagBlob,
  APIRosbagJob,
  APISharedUrl,
  APIStage,
  APIStatusTopic,
  APISubscribed,
  APITopic,
  APITopicConfig,
  APITopics,
  Deployment,
  Device,
  DeviceDetails,
  DeviceLog,
  DeviceLogUrl,
  FormattedMetricErrorLog,
  FormattedMetricItem,
  LogDetail,
  Metric,
  MetricErrorLog,
  Progress,
  ResTopics,
  Result,
  RosbagBlob,
  RosbagJob,
  SharedUrl,
  Stage,
  StatusTopic,
  Subscribed,
  SubscribeStatus,
  Topic,
  TopicConfig,
  Topics,
  TopicStateData,
} from './types';

export const mapAPIDeviceToDevice = (data: APIDevice): Device => ({
  status: data.status,
  uuid: data.uuid,
  registrationTime: data.registration_time,
  lastOnline: data.last_online,
  name: data.name,
  createdBy: data.created_by,
  description: data.description,
  labels: data.labels,
  pythonVersion: data.device_version,
  configVariables: data.config_variables,
  errorCode: data.error_code,
  errorMessage: data.error_message,
});

export const mapAPIDeviceListToDeviceList = (apiList: APIDevice[]): Device[] =>
  apiList.map(mapAPIDeviceToDevice);

export const mapAPIStageToStage = (data: APIStage): Stage => ({
  description: data.description,
  duration: data.duration,
  stage: data.stage,
  stageNumber: data.stage_number,
  startTime: data.start_time,
});

export const mapAPIProgressToProgress = (data: APIProgress): Progress => ({
  stages: data.stages.map(mapAPIStageToStage),
  totalStages: data.total_stages,
});

export const mapAPIMetricToMetric = (data: APIMetric): Metric => ({
  status: data.status,
  kind: data.kind,
  config: {
    qos: data.config.qos,
    isSystemMetric: data.config.is_system_metric,
  },
  name: data.name,
  settings: {
    interval: data.settings.interval,
    totalcpu: data.settings.totalcpu,
    collectCpuTime: data.settings.collect_cpu_time,
    reportActive: data.settings.report_active,
    percpu: data.settings.percpu,
  },
});

export const mapAPIMetricListToMetricList = (apiList: APIMetric[]): Metric[] =>
  apiList.map(mapAPIMetricToMetric);

export const mapAPIStatusTopicToStausTopic = (data: APIStatusTopic): StatusTopic => ({
  isDirectory: data.is_directory,
  name: data.name,
});

export const mapAPIStatusTopicListToStausTopicList = (apiList: APIStatusTopic[]): StatusTopic[] =>
  apiList.map(mapAPIStatusTopicToStausTopic);

export const mapAPISharedUrlToSharedUrl = (data: APISharedUrl): SharedUrl => ({
  id: data.id,
  urlUuid: data.url_uuid,
  expiryTime: data.expiry_time,
  createdAt: data.created_at,
  creator: data.creator,
});

export const mapAPILogDetailToLogDetail = (data: APILogDetail): LogDetail => ({
  createdAt: data.created_at,
  creator: data.creator,
  errorMessage: data.error_message,
  filename: data.filename,
  requestUuid: data.request_uuid,
  sharedUrls: data?.shared_urls?.map(mapAPISharedUrlToSharedUrl),
  status: data.status,
  updatedAt: data.updated_at,
  deviceId: data.device_id,
  totalSize: data.total_size,
});

export const mapAPIDeviceLogUrlToDeviceLogUrl = (data: APIDeviceLogUrl): DeviceLogUrl => ({
  signedUrl: data.signed_url,
});

export const mapAddDeviceToAPIAddDevice = (data: AddDevice): APIAddDevice => ({
  name: data.name,
  description: data.description,
  config_variables: data.configVariables,
});

export const mapTopicConfigToAPITopicConfig = (data: TopicConfig): APITopicConfig => ({
  qos: data.qos,
  whitelist_field: data.whitelistFields,
  whitelist_tag: data.whitelistTags,
});

export const mapTopicToAPITopic = (data: Topic): APITopic => ({
  config: mapTopicConfigToAPITopicConfig(data.config),
  name: data.name,
});

export const mapAPIDeploymentToDeployment = (data: APIDeployment): Deployment => ({
  ioDeploymentId: data.io_deployment_id,
  deploymentId: data.deployment_id,
  phase: data.phase,
  errorCode: data.error_code,
  createdAt: data.created_at,
});

export const mapAPIDeviceDetailToDeviceDetail = (data: APIDeviceDetails): DeviceDetails => ({
  status: data.status,
  uuid: data.uuid,
  registrationTime: data.registration_time,
  lastOnline: data.last_online,
  name: data.name,
  createdBy: data.created_by,
  description: data.description,
  labels: data.labels,
  configVariables: data.config_variables,

  deviceVersion: data.device_version,
  deployments: data.deployments.map(mapAPIDeploymentToDeployment),
  host: data.host,
  lsbDistribDescription: data.lsb_distrib_description,
  saltVersion: data.saltversion,
  username: data.username,
  ipInterfaces: data.ip_interfaces,
  errorCode: data.error_code,
  errorMessage: data.error_message,
  pythonVersion: data.device_version,
  installationMetadata: data?.installation_metadata?.stages
    ? mapAPIProgressToProgress(data.installation_metadata)
    : defaultInstallationMeta,
  isDeviceVpnEnabled: data.daemons_status?.vpn?.enable,
  deviceVpnStatus: data.daemons_status?.vpn?.status,
});

export const mapAPITopicConfigToTopicConfig = (data: APITopicConfig): TopicConfig => ({
  qos: data.qos,
  whitelistFields: data.whitelist_field,
  whitelistTags: data.whitelist_tag,
});

export const mapAPITopicToTopic = (data: APITopic): Topic => ({
  config: mapAPITopicConfigToTopicConfig(data.config),
  name: data.name,
});

export const mapAPISubscribedToSubscribed = (data: APISubscribed): Subscribed => ({
  metric: data.metric.map(mapAPITopicToTopic),
  log: data.log.map(mapAPITopicToTopic),
});

export const mapAPITopicsToTopics = (data: APITopics): Topics => ({
  unsubscribed: data.Unsubscribed,
  subscribed: mapAPISubscribedToSubscribed(data.Subscribed),
});

export const mapAPIResTopicsToResTopics = (data: APIResTopics): ResTopics => ({
  topics: mapAPITopicsToTopics(data.topics),
  masterUp: data.master_up,
});

export const mapAPIDeviceLogToDeviceLog = (data: APIDeviceLog): DeviceLog => ({
  createdAt: data.created_at,
  creator: data.creator,
  errorMessage: data.error_message,
  filename: data.filename,
  metadata: data.metadata,
  requestUuid: data.request_uuid,
  totalSize: data.total_size,
  status: data.status,
  updatedAt: data.updated_at,
});

export const mapAPIResultToResult = (data: APIResult): Result => ({
  statementId: data.statement_id,
  series: data.series,
});

export const mapAPIMetricErrorLogToMetricErrorLog = (data: APIMetricErrorLog): MetricErrorLog => ({
  results: data.results.map(mapAPIResultToResult),
  count: data.count,
  currentPage: data.current_page,
  pageSize: data.page_size,
});

export const mapAPIRosbagJobToRosbagJob = (data: APIRosbagJob): RosbagJob => ({
  createdAt: data.CreatedAt,
  deletedAt: data.DeletedAt,
  id: data.ID,
  updatedAt: data.UpdatedAt,
  deploymentID: data.deploymentID,
  deploymentName: data.deploymentName,
  guid: data.guid,
  name: data.name,
});

export const mapAPIRosbagBlobToRosbagBlob = (data: APIRosbagBlob): RosbagBlob => ({
  createdAt: data.CreatedAt,
  deletedAt: data.DeletedAt,
  id: data.ID,
  updatedAt: data.UpdatedAt,
  blobRefID: data.blobRefID,
  componentType: data.componentType,
  creator: data.creator,
  deviceID: data.deviceID,
  duration: data.duration,
  endTime: data.endTime,
  errorMessage: data.errorMessage,
  filename: data.filename,
  guid: data.guid,
  indexed: data.indexed,
  jobID: data.jobID,
  project: data.project,
  size: data.size,
  startTime: data.startTime,
  status: data.status,
  job: mapAPIRosbagJobToRosbagJob(data.job),
});

export const getFormattedMetricErrorLogs = (data: MetricErrorLog): FormattedMetricErrorLog => {
  const series = data?.results?.[0]?.series?.[0];
  const count = data?.count || 0;
  let list: FormattedMetricItem[] = [];

  if (series) {
    const values = series?.values ?? [];
    const columns = series?.columns;
    const dateIndex = columns.findIndex((column) => column === 'time');
    const dataIndex = columns.findIndex((column) => column === 'data');
    list = values.map((value) => ({
      key: nanoid(),
      timestamp: momentTz
        .utc(value[dateIndex], 'YYYY-MM-DDTHH:mm:ss.SSSSSS')
        .tz(momentTz.tz.guess())
        .format('Do MMM YYYY, h:mma'),
      message: value[dataIndex],
    }));
  }

  return {
    list,
    count,
  };
};

export const parseTopics = (data: string): ResTopics => {
  const apiResTopics = (typeof data === 'object' ? data : JSON.parse(data)) as APIResTopics;
  return mapAPIResTopicsToResTopics(apiResTopics);
};

export const getFormattedTopics = (
  topics: Topics,
  isMetricTagsFeatureEnabled?: boolean,
): Record<string, TopicStateData> => {
  const formattedDeviceTopics = {} as Record<string, TopicStateData>;
  topics.unsubscribed.forEach((topic) => {
    formattedDeviceTopics[topic] = {
      log: { status: SubscribeStatus.UNSUBSCRIBED },
      metric: { status: SubscribeStatus.UNSUBSCRIBED },
    };
  });
  ['metric', 'log'].forEach((type) => {
    topics.subscribed[type as keyof Subscribed].forEach((topic) => {
      const {
        config: { qos, whitelistFields: fieldOverride, whitelistTags: tagWhiteList },
      } = topic;

      if (!formattedDeviceTopics[topic.name]) {
        formattedDeviceTopics[topic.name] = {
          log: { status: SubscribeStatus.UNSUBSCRIBED },
          metric: { status: SubscribeStatus.UNSUBSCRIBED },
        };
      }
      formattedDeviceTopics[topic.name][type as keyof TopicStateData] = {
        status: SubscribeStatus.SUBSCRIBED,
        qos,
        ...(type === 'metric' &&
          isMetricTagsFeatureEnabled && {
            tagWhiteList,
            fieldOverride,
          }),
      };
    });
  });
  return formattedDeviceTopics;
};

export const mapLogTableKeysToReq = (sort: string): string => {
  const keys: Record<string, string> = {
    filename: 'filename',
    totalSize: 'total_size',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
  };
  return keys[sort] || '';
};

export const mapAPIAddDeviceResponseToAddDeviceResponse = (
  data: APIAddDeviceResponse,
): AddDeviceResponse => ({
  token: data.data,
  scriptCommand: data.script_command,
  deviceId: data.device_id,
});
