import { AxiosRequestConfig } from 'axios';

import { APICallStatus, IOHeaders, Limits, RIOError } from 'Models/common/types';
import { RecordOptions, Runtimes, UploadOptions, UploadType } from 'Models/devices/types';
import { APINetwork, Network } from 'Models/networks/types';
import DeploymentActionTypes from 'RioRedux/deployments/actionTypes';
import { Modify } from 'Root/types';

export enum DeploymentPhase {
  SUCCEEDED = 'Succeeded',
  IN_PROGRESS = 'In progress',
  PROVISIONING = 'Provisioning',
  PARTIALLY_DEPROVISIONED = 'Partially deprovisioned',
  FAILED_TO_START = 'Failed to start',
  FAILED_TO_UPDATE = 'Failed to update',
  DEPLOYMENT_STOPPED = 'Deployment stopped',
}
export enum DeploymentStatus {
  RUNNING = 'running',
  PENDING = 'pending',
  ERROR = 'error',
  UNKNOWN = 'unknown',
  STOPPED = 'stopped',
}
export enum APIDeploymentStatus {
  RUNNING = 'Running',
  PENDING = 'Pending',
  ERROR = 'Error',
  UNKNOWN = 'Unknown',
  STOPPED = 'Stopped',
}

export type DeploymentState = {
  deploymentId: string | null;
  actionApiStatus: APICallStatus;

  details: Deployment | null;
  detailsApiStatus: APICallStatus;
  detailsFetchError: RIOError | null;
  detailsFetchErrorCount: number;

  list: ListDeployment[];
  listApiStatus: APICallStatus;
  listFetchError: RIOError | null;

  collectRosbagsStatus: APICallStatus;
  updateRosbagJobStatus: APICallStatus;
};

export interface ExecutableMetaData {
  args: string | null;
  cmd: string[];
  docker: string;
  env: string | null;
  id: string;
  limits: Limits;
  name: string;
  simulationOptions?: {
    simulation: boolean;
  };
  buildGUID?: string;
}

export type ContainerState = {
  message: string;
  reason: string;
};

export type ContainerLastStateInfo = {
  containerID: string;
  exitCode: number;
  finishedAt: string;
  startedAt: string;
} & ContainerState;

export interface ContainerDetail {
  containerId: string;
  image: string;
  imageID: string;
  lastState: Record<string, ContainerLastStateInfo>;
  name: string;
  ready: boolean;
  restartCount: number;
  state: Record<string, ContainerState>;
}
export interface ContainerAndPodDetail {
  containerDetail: ContainerDetail[];
  podName: string;
  podStatus: string;
}
export type MetaData = ContainerAndPodDetail[];

export interface NetworkInfo {
  networkGUID: string;
  deploymentGUID: SVGStringList;
  networkType: string;
}
export interface ExecutablesStatusInfo {
  id: string;
  status: string;
  metadata: MetaData | null;
  reason?: string;
  networkInfo?: NetworkInfo;
}
export interface ComponentInfo {
  componentID: string;
  componentInstanceID: string;
  errorCode: string | null;
  executableMetaData: ExecutableMetaData[] | null;
  executablesStatusInfo: ExecutablesStatusInfo[];
  isDeprovisioned: boolean;
  name: string;
  phase: string;
  runtime: Runtimes;
  status: string;
}
export interface BuildDetails {
  buildGUID: string;
  buildGeneration: number;
  imageName: string;
  remoteImage: boolean;
}
export interface ComponentInstanceIds {
  componentId: string;
  componentInstanceId: string;
  errorCode: string;
  executableInfo: {
    buildInfo?: BuildDetails[];
  };
  packageId: string;
  planId: string;
  source: string;
}
export interface RosbagJob {
  name: string;
  recordOptions: RecordOptions;
  uploadOptions: UploadOptions;
}

export interface APICompOverride {
  // eslint-disable-next-line camelcase
  restart_policy: string;
}
export interface CompOverride {
  restartPolicy: string;
}

/* eslint-disable camelcase */
export interface APIDepComponentContext {
  ros_bag_job_defs: RosbagJob[];
  component_override: APICompOverride | null;
  static_route_config?: string[];
}
/* eslint-enable camelcase */
export interface DepComponentContext {
  rosBagJobDefs: RosbagJob[];
  componentOverride: CompOverride | null;
  staticRouteConfig?: string[];
}

export interface NetworkProvisionContext {
  guid: string;
  bindParameters?: {
    ROS_DOMAIN_ID: number;
  };
}
export interface APIProvisionContext {
  // eslint-disable-next-line camelcase
  component_context: Record<string, APIDepComponentContext>;
  dependentDeployments: DependentDeployments[];
  labels: string[];
  name: string;
  nativeNetworks: NetworkProvisionContext[];
  routedNetworks: NetworkProvisionContext[];
  // eslint-disable-next-line camelcase
  network_topics_map: Record<string, string[]>;
  managedServices?: Array<{ instance: string; name: string }> | null;
}
export type ProvisionContext = Omit<
  APIProvisionContext,
  'component_context' | 'network_topics_map' | 'managedServices'
> & {
  componentContext: Record<string, DepComponentContext>;
  networkTopicsMap: Record<string, string[]>;
  isVpnEnabled: boolean;
};

// TODO: Duplicate exists in the catalog changes merge it later on
export interface APIListDeployment {
  CreatedAt: string;
  DeletedAt: string | null;
  ID: number;
  UpdatedAt: string;
  bindable: boolean;
  creator: string;
  deploymentId: string;
  errorCode: string[];
  inUse: boolean;
  lastStatusUpdateTime: string;
  name: string;
  ownerProject: string;
  packageVersion: string;
  packageAPIVersion: string;
  packageId: string;
  packageName: string;
  phase: DeploymentPhase;
  planId: string;
  status: string;
  usedBy: string;
}

export type ListDeployment = Omit<
  APIListDeployment,
  'CreatedAt' | 'DeletedAt' | 'ID' | 'UpdatedAt'
> & {
  createdAt: string;
  deletedAt: string | null;
  id: number;
  updatedAt: string;
};

export interface APICoreNetwork {
  CreatedAt: string;
  DeletedAt: string | null;
  Deployment: null;
  ID: number;
  NativeNetwork: Network | null;
  RoutedNetwork: Network | null;
  UpdatedAt: string;
  deploymentGUID: string;
  networkGUID: string;
  networkType: string;
}

export interface Broker {
  host: string;
  name: string;
  password: string;
  port?: number;
  user: string;
}
export interface Topic {
  compression: string;
  name: string;
  qos: string;
  scoped: boolean;
  targeted: boolean;
}
export interface APIRos {
  isROS: boolean;
  // eslint-disable-next-line camelcase
  ros_distro: string;
  topics?: Topic[];
}
export type ROS = Omit<APIRos, 'ros_distro'> & {
  rosDistro: string;
};

export interface APIBrokerConfig {
  Broker: Broker;
  ROS: APIRos;
}
export interface BrokerConfig {
  broker: Broker;
  ros: ROS;
}

export interface APIDependencyBrokerConfig {
  // eslint-disable-next-line camelcase
  component_id: string;
  dependencyBrokerExternalConfigs: APIBrokerConfig[];
  dependencyBrokerInClusterConfigs: APIBrokerConfig[];
}
export interface DependencyBrokerConfig {
  componentId: string;
  dependencyBrokerExternalConfigs: BrokerConfig[];
  dependencyBrokerInClusterConfigs: BrokerConfig[];
}

// eslint-disable-next-line camelcase
export type APIParameterValue = APIDependencyBrokerConfig & { device_id: string };
export type ParameterValue = DependencyBrokerConfig & { deviceId?: string };

export type APIDeploymentParameters = Record<string, APIParameterValue>;
export type DeploymentParameters = Record<string, ParameterValue>;

export interface APIDependentDeploymentStatus {
  // eslint-disable-next-line camelcase
  component_info: ComponentInfo;
  error: string | null;
  status: string;
}
export type DependentDeploymentStatus = Omit<APIDependentDeploymentStatus, 'component_info'> & {
  componentInfo: ComponentInfo;
  diskGUID?: string;
};

export interface DependentDeployments {
  dependentDeploymentId: string;
}

export type DeploymentUpdate = Omit<APIDeploymentUpdate, 'CreatedAt'> & { createdAt: string };

export interface APIDeploymentUpdate {
  CreatedAt: string;
  generation: number;
  creator: string;
  success: boolean;
}

type DeploymentPartial = Omit<APIDeployment, 'CreatedAt' | 'DeletedAt' | 'ID' | 'UpdatedAt'> & {
  createdAt: string;
  deletedAt: string | null;
  id: number;
  updatedAt: string;
};
export type Deployment = Modify<
  DeploymentPartial,
  {
    dependentDeploymentStatus: Record<string, DependentDeploymentStatus>;
    parameters: DeploymentParameters;
    provisionContext: ProvisionContext;
    status: DeploymentStatus;
    deploymentUpdates: DeploymentUpdate[];
    vpnStatus: string | undefined;
  }
>;

export interface APIDeployment {
  CreatedAt: string;
  DeletedAt: string | null;
  ID: number;
  UpdatedAt: string;
  bindable: boolean;
  componentInfo: ComponentInfo[];
  componentInstanceIds: ComponentInstanceIds[];
  coreNetworks?: APICoreNetwork[];
  creator: string;
  currentGeneration: number;
  dependentDeploymentStatus: Record<string, APIDependentDeploymentStatus>;
  dependentDeployments: DependentDeployments[];
  deploymentId: string;
  errorCode?: string[];
  errors: string[] | null;
  inUse: boolean;
  labels: string[];
  lastStatusUpdateTime: string;
  name: string;
  ownerProject: string;
  packageAPIVersion: string;
  packageDependencyStatus: Record<string, any>;
  packageId: string;
  packageName: string;
  parameters: APIDeploymentParameters;
  phase: DeploymentPhase;
  planId: string;
  provisionContext: APIProvisionContext;
  status: APIDeploymentStatus;
  usedBy: string;
  deploymentUpdates?: APIDeploymentUpdate[];
}

export interface DeploymentPhaseRequest {
  phaseList: string[];
  deviceID?: string;
  headers?: Partial<IOHeaders>;
}

export interface DeprovisionDepRequest {
  deploymentId: string;
  packageId: string;
  planId: string;
}
/* eslint-disable camelcase */
export interface APIComponentError {
  component_id: string;
  component_name: string;
  error_code: string;
  is_deprovisioned: boolean;
}
/* eslint-enable camelcase */

export interface APIDeprovisionDepResponse {
  async: boolean;
  // eslint-disable-next-line camelcase
  component_status: APIComponentError[] | null;
}

export interface DeploymentDetailsRequest {
  deploymentId: string;
  updateHistory?: boolean;
  queryCache?: boolean;
  axiosOptions?: Partial<AxiosRequestConfig>;
}

export interface Executable {
  buildGUID: string;
  id: string;
  name: string;
}
export interface Component {
  executables: Executable[];
  // eslint-disable-next-line camelcase
  update_deployment: boolean;
}
export interface APIComponentContext {
  // eslint-disable-next-line camelcase
  component_context: Record<string, Component>;
}

export interface ComponentContext {
  // eslint-disable-next-line camelcase
  componentContext: Record<string, Component>;
}

export interface UpdateDeploymentRequest {
  context: ComponentContext;
  planId: string;
  serviceId: string;
}

/* eslint-disable camelcase */
export interface APIUpdateDeploymentRequest {
  context: APIComponentContext;
  plan_id: string;
  service_id: string;
}
/* eslint-enable camelcase */

export enum BrokerErrorQueryType {
  COUNT = 'count',
  HISTOGRAM = 'histogram',
}

// Capitalised Runtime
export enum RunTimeCapital {
  DEVICE = 'Device',
  CLOUD = 'Cloud',
}
export interface APIRosbag {
  CreatedAt: string;
  DeletedAt: string | null;
  ID: number;
  UpdatedAt: string;
  componentID: string;
  componentInstanceID: string;
  componentType: RunTimeCapital;
  creator: string;
  deploymentID: string;
  deprovisioned: boolean;
  guid: string;
  name: string;
  packageID: string;
  project: string;
  recordOptions: RecordOptions;
  status: string;
  uploadOptions?: UploadOptions;
  overrideOptions: OverrideOptions;
  errorMessage?: string;
}

export interface StopRosbagRequest {
  deploymentID: string;
  jobGuids: string[];
}

export interface MessageType {
  md5: string;
  type: string;
}
export interface TopicBag {
  frequency: number;
  messageCount: number;
  messageType: string;
  name: string;
}
export interface BlobInfo {
  bagVersion: string;
  compressedSize: number;
  compression: string;
  duration: number;
  endTime: number;
  indexed: boolean;
  messageCount: number;
  messageTypes: MessageType[] | null;
  size: number;
  startTime: number;
  topics: TopicBag[] | null;
  uncompressedSize: number;
}
export interface APIRosbagBlob {
  CreatedAt: string;
  DeletedAt: string | null;
  ID: number;
  UpdatedAt: string;
  blobRefID: number;
  componentType: RunTimeCapital;
  creator: string;
  errorMessage: string;
  filename: string;
  guid: string;
  info: BlobInfo;
  job: APIRosbag;
  jobID: number;
  project: string;
  status: string;
}

export interface DownloadBlob {
  url: string;
}

export interface CreateRosbagRequest {
  deploymentID: string;
  data: RosbagData;
}

export interface RecordOptionsRequest {
  compression?: string;
  maxMessageCount?: number;
  maxSplitSize?: number;
  maxSplits?: number;
  node?: string;
  topicExcludeRegex?: string;
  topicInludeRegex?: string[];
  topics: string[];
}

export interface TopicInfo {
  topicName: string;
  recordFrequency?: number;
  latched: boolean;
}

export interface OverrideOptions {
  topicOverrideInfo: TopicInfo[];
  excludeTopics?: string[];
}

export interface RosbagData {
  componentInstanceID: string;
  name: string;
  recordOptions: RecordOptionsRequest;
  overrideOptions?: OverrideOptions;
}

export type DeploymentDep = Pick<
  Deployment,
  'deploymentId' | 'name' | 'packageId' | 'packageName' | 'phase'
>;

export interface Graph {
  destination: string;
  edgeType: string;
  name: string;
}
export interface DeploymentDependencies {
  deployments: Record<string, DeploymentDep>;
  graph: Record<string, Graph>;
  routedNetworks: APINetwork[];
}

export interface DeploymentBindingRequest {
  deploymentId: string;
  packageId: string;
  planId: string;
}

export interface DeploymentBindingResponse {
  async: boolean;
}

/* *** Broker Error *** */

/* eslint-disable camelcase */
export interface APIBrokerSource {
  '@timestamp': string;
  component_id: string;
  deployment_id: string;
  device_id: string;
  executable_id: string;
  measurement_name: string;
  organization_id: string;
  stderr: {
    msg: string;
  };
  stdout: {
    msg: string;
  };
  tenant_id: string;
}
/* eslint-enable camelcase */

export type APIBrokerHit = Modify<
  APIHits,
  {
    _source: APIBrokerSource;
  }
>;

export type APIBrokerError = Modify<
  APIHistoricalLogs,
  {
    hits: {
      total: number;
      // eslint-disable-next-line camelcase
      max_score: null;
      hits: APIBrokerHit[];
    };
  }
>;

export interface BrokerSource {
  timestamp: string;
  componentId: string;
  deploymentId: string;
  deviceId: string;
  executableId: string;
  measurementName: string;
  organizationId: string;
  stderr: {
    msg: string;
  };
  stdout: {
    msg: string;
  };
  tenantId: string;
}

export type BrokerHit = Modify<Hits, { source: BrokerSource }>;

export type BrokerErrorInfo = Modify<
  HistoricalLogs,
  {
    hits: {
      total: number;
      maxScore: null;
      hits: BrokerHit[];
    };
  }
>;

export interface BrokerErrorRequestOptions {
  depId: string;
  compId: string;
  execId: string;
  queryType: BrokerErrorQueryType;
}

/* *** Logs and Historical logs *** */
export interface LiveLogsRequestOptions {
  dataCallback: (streamData: string, xhr: XMLHttpRequest) => null;
  endCallback: (xhr: XMLHttpRequest) => null;
  resourceId: string;
  componentId: string;
  executableId: string;
  instanceId: string;
}
export enum HIST_LOG_TYPES {
  ALL = 'both',
  STDERR = 'stderr',
  STDOUT = 'stdout',
}

export interface Bucket {
  docCount: number;
  keyAsString: string;
  key: string;
}

/* eslint-disable camelcase */
export interface APIBucket {
  doc_count: number;
  key_as_string: string;
  key: string;
}
/* eslint-enable camelcase */

export interface Aggregations {
  timeaggregation: {
    buckets: Bucket[];
  };
}

export interface APIAggregations {
  timeaggregation: {
    buckets: APIBucket[];
  };
}

export interface SourceCommon {
  esindexname: string;
  stdout: {
    msg: string;
  };
  stderr: {
    msg: string;
  };
  journaldts: string;
  node: string;
}
export type Source = {
  priority: string;
  timestamp: string;
  podName: string;
  componentId: string;
  executableId: string;
  deploymentId: string;
  version: string;
} & SourceCommon;

/* eslint-disable camelcase */
export type APISource = {
  PRIORITY: string;
  '@timestamp': string;
  pod_name: string;
  component_id: string;
  deployment_id: string;
  executable_id: string;
  '@version': string;
} & SourceCommon;
/* eslint-enable camelcase */

export interface Hits {
  sort: number[];
  fields: null;
  highlight: null;

  score: null | number;
  index: string;
  type: string;
  id: string;
  uid: string;
  routing: string;
  parent: string;
  version: number | null;
  source: Source;
  explanation: null;
  matchedQueries: null;
  innerHits: null;
}
export interface APIHits {
  _score: null | number;
  _index: string;
  _type: string;
  _id: string;
  _uid: string;
  _routing: string;
  _parent: string;
  _version: number | null;
  sort: number[];
  highlight: null;
  _source: APISource;
  fields: null;
  _explanation: null;
  // eslint-disable-next-line camelcase
  matched_queries: null;
  // eslint-disable-next-line camelcase
  inner_hits: null;
}

export interface HistoricalLogs {
  took: number;
  scrollId: string;
  hits: {
    total: number;
    maxScore: null | number;
    hits: Hits[];
  };
  suggest: null;
  aggregations: null | Aggregations;
  timedOut: boolean;
  shards: {
    total: number;
    successful: number;
    failed: number;
  };
}

/* eslint-disable camelcase */
export interface APIHistoricalLogs {
  took: number;
  _scroll_id: string;
  hits: {
    total: number;
    max_score: null | number;
    hits: APIHits[];
  };
  suggest: null;
  aggregations: null | APIAggregations;
  timed_out: boolean;
  _shards: {
    total: number;
    successful: number;
    failed: number;
  };
}
/* eslint-enable camelcase */

export interface HistoricalLogsRequestOptions {
  deploymentId: string;
  type: HIST_LOG_TYPES;
  download?: boolean;
  useScroll?: boolean;
  componentId?: string[];
  executableId?: string[];
  endTime?: string;
  startTime?: string;
  timezone?: string;
  from?: string;
  isBlob?: boolean;
  queryString?: string;
  scrollId?: string;
  size?: string;
}

export type APIHistoricalLogsRequestOptions = Modify<
  HistoricalLogsRequestOptions,
  {
    componentId?: string;
    executableId?: string;
  }
>;

export interface CreateDeploymentReq {
  instanceId: string;
  acceptsIncomplete: boolean;
  serviceId: string;
  planId: string;
  organizationGUID: string;
  spaceGUID: string;
  // TODO: Figure of the type of this as it differs from DeploymentParameters
  parameters: Record<string, any>;
  global: Partial<{ deviceIds: string[] }>;
  context: ProvisionContext;
}

/* eslint-disable camelcase */
export interface APICreateDeploymentReq {
  instance_id: string;
  accepts_incomplete: boolean;
  service_id: string;
  plan_id: string;
  organization_guid: string;
  space_guid: string;
  parameters: Record<string, any>;
  global: Partial<{ device_ids: string[] }>;
  context: APIProvisionContext;
}
/* eslint-enable camelcase */
export interface CreateDeploymentRes {
  operation: string;
}

/* *** Action *** */
interface BaseDeploymentAction {
  type: DeploymentActionTypes;
}
export type DeploymentAction<P = undefined> = P extends undefined
  ? BaseDeploymentAction
  : BaseDeploymentAction & { payload: P };

export interface UpdateDeploymentPayload {
  deploymentId: string | null;
  status: APICallStatus;
}

export type DeploymentActionPayload =
  | string
  | RIOError
  | Deployment
  | ListDeployment[]
  | UpdateDeploymentPayload
  | boolean
  | APICallStatus;

export interface ExecTreeNode {
  checked: boolean;
  execId: string;
  key: string;
  title: string;
}

export interface CompTreeNode {
  checked: boolean;
  children: ExecTreeNode[];
  key: string;
  title: string;
}

export enum CombinedDeploymentStatus {
  RUNNING = 'running',
  ERROR = 'error',
  STOPPED = 'stopped',
  DISCONNECTED = 'disconnected',
  UNKNOWN = 'unknown',
  IN_PROGRESS = 'in-progress',
  PARTIALLY_DEPROVISIONED = 'partially-deprovisioned',
}

export enum DeploymentSortOption {
  NAME = 'name',
  STARTED = 'createdAt',
  STATUS = 'status',
}
export interface UpdateROSBagJobParams {
  deploymentID: string;
  componentInstanceID: string;
  jobID: string;
  uploadType?: UploadType;
  timeRange?: {
    from: number;
    to: number;
  };
}

export type UpdateROSBagJob = (params: UpdateROSBagJobParams) => void;
