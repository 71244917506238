import { Limits } from 'Models/common/types';
import { Runtimes } from 'Models/devices/types';

export interface APINetwork {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  name: string;
  guid: string;
  ownerProject: string;
  creator: string;
  runtime: Runtimes;
  rosDistro: string;
  shared?: boolean;
  internalDeploymentGUID: string;
  internalDeploymentStatus: APIInternalDeploymentStatus;
  parameters: CloudParams | APIDeviceParams;
}

export interface Network {
  id: number;
  createdAt: string;
  updatedAt?: string;
  deletedAt: string | null;
  name: string;
  guid: string;
  ownerProject: string;
  creator: string;
  runtime: Runtimes;
  rosDistro: string;
  shared?: boolean;
  internalDeploymentGUID: string;
  internalDeploymentStatus: InternalDeploymentStatus;
  parameters: CloudParams | DeviceParams;
  type?: NetworkTypes;
}

export interface APIInternalDeploymentStatus {
  // eslint-disable-next-line camelcase
  error_code?: string[];
  phase: NetworkPhases;
  status?: NetworkStatus;
}

export interface InternalDeploymentStatus {
  errorCode?: string[];
  phase: NetworkPhases;
  status?: NetworkStatus;
}

export interface APICommonParams {
  /* eslint-disable camelcase */
  server_id?: number;
  server_port?: number;
  /* eslint-enable camelcase */
}

export interface APICloudParams extends APICommonParams {
  limits: Limits;
}

export interface CommonParams {
  serverId?: number;
  serverPort?: number;
}

export interface CloudParams extends CommonParams {
  limits: Limits;
}

export interface CreateNetworkPayload {
  name: string;
  runtime: string;
  rosDistro: string;
  shared?: boolean;
  parameters: CloudParams | DeviceParams;
}

export interface APIDeviceParams extends APICommonParams {
  /* eslint-disable camelcase */
  device_id: string;
  NETWORK_INTERFACE: string;
  restart_policy: string;
  /* eslint-enable camelcase */
}

export interface DeviceParams extends CommonParams {
  deviceId: string;
  networkInterface: string;
  restartPolicy: string;
}

export interface GetNetworksPayload {
  phaseList: string[];
  deviceUID: string;
}

export interface CreateNetworkResponse {
  name: string;
  guid: string;
}

export enum NetworkStatus {
  RUNNING = 'Running',
  ERROR = 'Error',
  DEPLOYMENT_NOT_RUNNING = 'Deployment not running',
  PENDING = 'Pending',
  UNKNOWN = 'Unknown',
}

export interface DeleteNetworkParams {
  name: string;
  guid: string;
  type: string;
}

export interface FilterOptionsNetwork {
  phase: NetworkPhases[] | null;
  runtime: Runtimes[] | null;
  search: string;
  type: NetworkTypes[] | null;
}

export enum NetworkPhases {
  IN_PROGRESS = 'In progress',
  SUCCEEDED = 'Succeeded',
  FAILED = 'Failed to start',
  PARTIALLY_DEPROVISIONED = 'Partially deprovisioned',
  PROVISIONING = 'Provisioning',
  DEPLOYMENT_STOPPED = 'Deployment stopped',
}

export enum NetworkTypes {
  ROUTED = 'routed',
  NATIVE = 'native',
}

export interface DetailItem {
  title?: string | JSX.Element;
  value?: string | number | JSX.Element;
}
