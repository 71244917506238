enum ActionTypes {
  SET_LIST_API_STATUS = 'DISKS/SET_LIST_API_STATUS',
  SET_LIST_API_DATA = 'DISKS/SET_LIST_API_DATA',

  SET_DETAILS_API_STATUS = 'DISKS/SET_DETAILS_API_STATUS',
  SET_DETAILS_API_DATA = 'DISKS/SET_DETAILS_API_DATA',

  SET_CREATE_API_STATUS = 'DISKS/SET_CREATE_API_STATUS',

  SET_DELETE_API_STATUS = 'DISKS/SET_DELETE_API_STATUS',
}

export default ActionTypes;
