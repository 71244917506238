import { BROKER_RESOURCE_LIMITS, NATIVE_NETWORK_LIMITS } from 'Shared/config';
import { isEmptyObj, omitEmpty } from 'Shared/utils/core/object';

import { ConfigVariable, ConfigVars, Device } from '../devices/types';
import { Disk } from '../disks/types';
import { CloudParams, DeviceParams, Network, NetworkTypes } from '../networks/types';
import { Secret } from '../secrets/types';

import {
  CLIDeviceSpec,
  CLIDiskSpec,
  CLIDockerSecretSpec,
  CLIManifest,
  CLINetworkSpec,
  CLISecretTypes,
  DockerSpec,
  ManifestKinds,
  ManifestMeta,
  PreinstalledSpec,
} from './types';

export const getManifest = <T = undefined, U = ManifestMeta>(
  kind: ManifestKinds,
  metadata: U,
  spec?: T,
): CLIManifest<T, U> => ({
  apiVersion: 'apiextensions.rapyuta.io/v1',
  kind,
  metadata,
  spec,
});

export const PASSWORD_PLACEHOLDER = 'REPLACE THIS';

const spinalCaseToCamelCase = (spinalStr?: string): string | undefined =>
  spinalStr?.replace(/-./g, (match) => match.replace('-', '').toUpperCase());

export const networkToCLINetworkSpec = (network: Network): Partial<CLINetworkSpec> => {
  const limits =
    network.type === NetworkTypes.NATIVE ? NATIVE_NETWORK_LIMITS : BROKER_RESOURCE_LIMITS;

  let resourceLimits: string | undefined;

  if ((network.parameters as CloudParams).limits) {
    const resourceLimitsKey = Object.entries<typeof limits>(limits).find(
      ([, value]) => value.memory === (network.parameters as CloudParams).limits.memory / 1024,
    )?.[0];

    resourceLimits = spinalCaseToCamelCase(resourceLimitsKey);
  }

  const deviceParams = network.parameters as DeviceParams;

  const networkSpec: CLINetworkSpec = {
    runtime: network.runtime,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    type: network.type!,
    rosDistro: network.rosDistro,
    resourceLimits,
    deviceGUID: deviceParams.deviceId,
    networkInterface: deviceParams.networkInterface,
  };

  const finalSpec = omitEmpty(networkSpec);

  return finalSpec;
};

export const secretToCLISecretSpec = (secret: Secret): CLIDockerSecretSpec => {
  const data = {
    registry: secret.registry,
    username: secret.username,
    email: secret.email,
    password: PASSWORD_PLACEHOLDER,
  };

  const dockerSecret: CLIDockerSecretSpec = {
    type: CLISecretTypes.DOCKER,
    docker: data,
  };

  return dockerSecret;
};

export const diskToCLIDiskSpec = (disk: Disk): CLIDiskSpec => {
  const { labels } = disk;
  const diskSpec: CLIDiskSpec = {
    runtime: disk.runtime,
    capacity: disk.capacity,
  };

  if (!isEmptyObj(disk.labels)) {
    diskSpec.labels = labels;
  }

  return diskSpec;
};

const hasRuntimeConfig = (configVars: ConfigVariable[], runtime: ConfigVars): boolean =>
  configVars.some((config) => config.key === runtime && config.value === 'True');

export const deviceToCLIDeviceSpec = (device: Device): CLIDeviceSpec => {
  const deviceSpec: CLIDeviceSpec = {
    python: device.pythonVersion,
  };

  const hasPreInstalled = hasRuntimeConfig(device.configVariables, ConfigVars.PRE_INSTALLED);
  const hasDocker = hasRuntimeConfig(device.configVariables, ConfigVars.DOCKER);

  if (hasPreInstalled) {
    const piSpec: PreinstalledSpec = {
      enabled: true,
    };

    const catkinWorkspace = device.configVariables.find(
      (config) => config.key === ConfigVars.ROS_WORKSPACE,
    )?.value;

    if (catkinWorkspace) {
      piSpec.catkinWorkspace = catkinWorkspace;
    }

    deviceSpec.preinstalled = piSpec;
  }

  if (hasDocker) {
    const dockerSpec: DockerSpec = {
      enabled: true,
    };

    const rosbagMountPath = device.configVariables.find(
      (config) => config.key === ConfigVars.ROSBAG_MOUNT_PATH,
    )?.value;

    if (rosbagMountPath) {
      dockerSpec.rosbagMountPath = rosbagMountPath;
    }

    deviceSpec.docker = dockerSpec;
  }

  return deviceSpec;
};
