import CLIManifestActionTypes from 'RioRedux/cliManifest/actionTypes';

import { RunTime } from '../common/types';
import { Runtimes } from '../devices/types';
import { NetworkTypes } from '../networks/types';

export interface CLIManifest<T = undefined, U = ManifestMeta> {
  apiVersion: string;
  kind: ManifestKinds;
  spec?: T;
  metadata: U;
}

export interface ManifestMeta {
  name: string;
}

export interface DeviceMeta extends ManifestMeta {
  project: string;
}

export enum ManifestKinds {
  STATIC_ROUTE = 'StaticRoute',
  NETWORK = 'Network',
  SECRET = 'Secret',
  DISK = 'Disk',
  DEVICE = 'Device',
}

export interface CLINetworkSpec {
  runtime: Runtimes;
  type: NetworkTypes;
  rosDistro: string;
  resourceLimits?: string;
  deviceGUID?: string;
  networkInterface?: string;
}

export enum CLISecretTypes {
  DOCKER = 'Docker',
}

export interface CLIDockerSecretSpec {
  type: CLISecretTypes;
  docker: {
    username: string;
    password: string;
    email: string;
    registry: string;
  };
}

export interface CLIDiskSpec {
  runtime: RunTime;
  capacity: number;
  labels?: Record<string, string>;
}

export interface PreinstalledSpec {
  enabled: boolean;
  catkinWorkspace?: string;
}

export interface DockerSpec {
  enabled: boolean;
  rosbagMountPath?: string;
}
export interface CLIDeviceSpec {
  python: string;
  preinstalled?: PreinstalledSpec;
  docker?: DockerSpec;
}

interface BaseCLIManifestAction {
  type: CLIManifestActionTypes;
}

export type CLIManifestAction<P = undefined> = P extends undefined
  ? BaseCLIManifestAction
  : BaseCLIManifestAction & { payload: P };

export type CLIManifestActionPayload = CLIManifest;
