import ActionTypes from 'RioRedux/disks/actionTypes';

import { APICallStatus, RIOError, RunTime } from '../common/types';

export type CreateDiskFormData = Omit<CreateDiskReq, 'labels'> & {
  labels: Array<{ key: string; value: string }>;
};

export type CreateDiskReq = Pick<Disk, 'name' | 'capacity' | 'diskType' | 'labels' | 'runtime'>;

export enum DiskType {
  SSD = 'ssd',
}

export enum DiskStatus {
  PENDING = 'Pending',
  AVAILABLE = 'Available',
  BOUND = 'Bound',
  RELEASED = 'Released',
  RUNNING = 'Running',
  FAILED = 'Failed',
}

export interface Disk {
  name: string;
  guid: string;
  diskType: DiskType;
  capacity: number;
  runtime: RunTime;
  status: DiskStatus;
  internalDeploymentGUID: string;
  usedBy: string;
  usedByDeploymentName: string;
  labels: Record<string, string>;
  createdAt: string;
  errors: string[];
}

export interface DisksState {
  list: Disk[];
  listApiStatus: APICallStatus;
  createDiskApiStatus: APICallStatus;
  deleteDiskApiStatus: APICallStatus;
  diskDetails: Disk;
  detailsApiStatus: APICallStatus;
  error: RIOError | null;
}

export type DisksActionPayload = Disk[] | Disk | APICallStatus;

export interface DisksAction<P = undefined> {
  type: ActionTypes;
  payload?: P;
}
