import { APICallStatus, RIOError } from 'Models/common/types';
import {
  Deployment,
  DeploymentAction,
  DeploymentActionPayload,
  DeploymentState,
  DeploymentStatus,
  ListDeployment,
  UpdateDeploymentPayload,
} from 'Models/deployments/types';

import DeploymentActionTypes from './actionTypes';

export const initialState: DeploymentState = {
  deploymentId: null,
  actionApiStatus: APICallStatus.INITIAL,

  details: null,
  detailsApiStatus: APICallStatus.INITIAL,
  detailsFetchError: null,
  detailsFetchErrorCount: 0,

  list: [],
  listApiStatus: APICallStatus.INITIAL,
  listFetchError: null,

  collectRosbagsStatus: APICallStatus.INITIAL,
  updateRosbagJobStatus: APICallStatus.INITIAL,
};

export default (
  state: DeploymentState = initialState,
  action: DeploymentAction<DeploymentActionPayload>,
): DeploymentState => {
  switch (action.type) {
    case DeploymentActionTypes.DEPLOYMENTS_GET_PENDING:
      return {
        ...state,
        listApiStatus: APICallStatus.LOADING,
        listFetchError: null,
      };

    case DeploymentActionTypes.DEPLOYMENTS_GET_FULFILLED:
      return {
        ...state,
        listApiStatus: APICallStatus.LOADED,
        list: action.payload as ListDeployment[],
        listFetchError: null,
      };

    case DeploymentActionTypes.DEPLOYMENTS_GET_REJECTED:
      return {
        ...state,
        list: [],
        listApiStatus: APICallStatus.ERROR,
        listFetchError: action.payload as RIOError,
      };

    case DeploymentActionTypes.UPDATE_DEPLOYMENTS_LIST:
      return {
        ...state,
        list: action.payload as ListDeployment[],
      };

    case DeploymentActionTypes.DEPLOYMENT_DELETE_FULFILLED: {
      const cDepList =
        state.list?.map((dep) => {
          if (dep.deploymentId === action.payload) {
            return {
              ...dep,
              phase: 'stopped',
            };
          }

          return dep;
        }) ?? null;

      const depIdMatched = state.deploymentId === action.payload;
      const depDetails =
        depIdMatched && state.details
          ? {
              details: {
                ...state.details,
                status: DeploymentStatus.STOPPED,
              },
            }
          : {};

      return {
        ...state,
        ...depDetails,
        actionApiStatus: APICallStatus.INITIAL,
        list: cDepList,
      };
    }

    // details related reducer case
    case DeploymentActionTypes.DEPLOYMENT_PAGE_SET_ID: {
      const payload = action.payload as string;
      const depIdNotMatched = state.deploymentId !== payload;
      if (depIdNotMatched) {
        return { ...initialState, deploymentId: payload };
      }
      return state;
    }

    case DeploymentActionTypes.DEPLOYMENT_DELETE_PENDING:
      return {
        ...state,
        actionApiStatus: APICallStatus.LOADING,
      };

    case DeploymentActionTypes.DEPLOYMENT_DELETE_REJECTED:
      return {
        ...state,
        actionApiStatus: APICallStatus.INITIAL,
      };

    case DeploymentActionTypes.UPDATE_DEPLOYMENT: {
      const { status, deploymentId } = action.payload as UpdateDeploymentPayload;

      return {
        ...state,
        actionApiStatus: status,
        deploymentId,
      };
    }

    case DeploymentActionTypes.DEPLOYMENT_DETAILS_GET_PENDING:
      return { ...state, detailsApiStatus: APICallStatus.LOADING };

    case DeploymentActionTypes.DEPLOYMENT_DETAILS_GET_FULFILLED: {
      const depIdMatch = state.deploymentId === (action.payload as Deployment).deploymentId;
      const onDepIdMatchObj = {
        ...state,
        detailsApiStatus: APICallStatus.LOADED,
        details: action.payload as Deployment,
        detailsFetchErrorCount: 0,
        detailsFetchError: null,
      };
      return depIdMatch ? onDepIdMatchObj : state;
    }

    case DeploymentActionTypes.DEPLOYMENT_DETAILS_GET_REJECTED: {
      const { detailsFetchErrorCount } = state;
      return {
        ...state,
        detailsApiStatus: APICallStatus.ERROR,
        detailsFetchError: action.payload as RIOError,
        detailsFetchErrorCount: detailsFetchErrorCount + 1,
        details: null,
      };
    }

    case DeploymentActionTypes.SET_COLLECT_ROSBAGS_STATUS: {
      return {
        ...state,
        collectRosbagsStatus: action.payload as APICallStatus,
      };
    }

    case DeploymentActionTypes.SET_UPDATE_ROSBAG_JOB_STATUS: {
      return {
        ...state,
        updateRosbagJobStatus: action.payload as APICallStatus,
      };
    }

    default:
      return state;
  }
};
