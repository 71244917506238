import { APICallStatus, RunTime } from 'Models/common/types';
import {
  Disk,
  DisksAction,
  DisksActionPayload,
  DisksState,
  DiskStatus,
  DiskType,
} from 'Models/disks/types';

import ActionTypes from './actionTypes';

export const initialState: DisksState = {
  list: [],
  listApiStatus: APICallStatus.INITIAL,
  createDiskApiStatus: APICallStatus.INITIAL,
  deleteDiskApiStatus: APICallStatus.INITIAL,
  diskDetails: {
    name: '',
    guid: '',
    diskType: DiskType.SSD,
    capacity: 0,
    runtime: RunTime.CLOUD,
    status: DiskStatus.RELEASED,
    internalDeploymentGUID: '',
    usedBy: '',
    usedByDeploymentName: '',
    labels: {},
    errors: [],
    createdAt: '',
  },
  detailsApiStatus: APICallStatus.INITIAL,
  error: null,
};

const DisksReducer = (
  state: DisksState = initialState,
  action: DisksAction<DisksActionPayload>,
): DisksState => {
  switch (action.type) {
    case ActionTypes.SET_CREATE_API_STATUS:
      return { ...state, createDiskApiStatus: action.payload as APICallStatus };

    case ActionTypes.SET_DELETE_API_STATUS:
      return { ...state, deleteDiskApiStatus: action.payload as APICallStatus };

    case ActionTypes.SET_LIST_API_STATUS:
      return { ...state, listApiStatus: action.payload as APICallStatus };

    case ActionTypes.SET_LIST_API_DATA:
      return { ...state, list: action.payload as Disk[], listApiStatus: APICallStatus.LOADED };

    case ActionTypes.SET_DETAILS_API_STATUS:
      return { ...state, detailsApiStatus: action.payload as APICallStatus };

    case ActionTypes.SET_DETAILS_API_DATA:
      return {
        ...state,
        diskDetails: action.payload as Disk,
        detailsApiStatus: APICallStatus.LOADED,
      };

    default:
      return state;
  }
};

export default DisksReducer;
