/* eslint-disable camelcase */
import { ProjectFeatureStatus } from 'Models/projects/types';
import ActionTypes from 'RioRedux/devices/actionTypes';

import { APICallStatus, RIOError } from '../common/types';

export enum DeviceStatus {
  ERROR = 'ERROR',
  FAILED = 'FAILED',
  REGISTERED = 'REGISTERED',
  INITIALIZING = 'INITIALIZING',
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  REJECTED = 'REJECTED',
}

export interface ApiResDevices<T> {
  status: string;
  data: T;
  error?: string;
}

export interface SaveVPNDetailsPayload {
  vpn: boolean;
  config?: {
    vpn: {
      advertise_routes: boolean;
    };
  };
}
export interface APIDeviceDetails extends APIDevice {
  device_version: string;
  deployments: APIDeployment[];
  host: string;
  lsb_distrib_description: string;
  saltversion: string;
  username: string;
  ip_interfaces: Record<string, string[]>;
  error_code?: string;
  error_message?: string;
  installation_metadata?: APIProgress;
}

export interface DeviceDetails extends Device {
  deviceVersion: string;
  deployments: Deployment[];
  host: string;
  lsbDistribDescription: string;
  saltVersion: string;
  username: string;
  ipInterfaces: Record<string, string[]>;
  installationMetadata: Progress;
  isDeviceVpnEnabled?: boolean;
  deviceVpnStatus?: ProjectFeatureStatus;
}

export interface APIDeployment {
  io_deployment_id: string;
  deployment_id: string;
  phase: string;
  error_code?: string;
  created_at: string;
}

export interface Deployment {
  ioDeploymentId: string;
  deploymentId: string;
  phase: string;
  errorCode?: string;
  createdAt: string;
}

export type APIDevice = Omit<
  Device,
  | 'registrationTime'
  | 'deleteStatus'
  | 'lastOnline'
  | 'createdBy'
  | 'configVariables'
  | 'errorCode'
  | 'errorMessage'
> & {
  registration_time: string;
  last_online: string | null;
  created_by: string;
  device_version: string;
  config_variables: ConfigVariable[];
  error_code?: string;
  error_message?: string;
  daemons_status: {
    vpn?: {
      enable: boolean;
      status: ProjectFeatureStatus;
    };
  };
};

export interface Device {
  status: DeviceStatus;
  uuid: string;
  deleteStatus?: string;
  registrationTime: string;
  lastOnline: string | null;
  name: string;
  createdBy: string;
  description: string;
  labels: Label[];
  pythonVersion: string;
  configVariables: ConfigVariable[];
  errorCode?: string;
  errorMessage?: string;
}

export interface ConfigVariable {
  id: number;
  key: string;
  value: string;
}

export interface Label {
  id: number;
  key: string;
  value: string;
  isDirty?: boolean;
  deletePending?: boolean;
  updatePending?: boolean;
}

export interface APIProgress {
  stages: APIStage[];
  total_stages: number;
}

export interface Progress {
  stages: Stage[];
  totalStages: number;
}

export type APIStage = Omit<Stage, 'stageNumber' | 'startTime'> & {
  stage_number: number;
  start_time: number;
};

export interface Stage {
  description: string;
  duration: number;
  stage: string;
  stageNumber: number;
  startTime: number;
}

export type APIMetric = Omit<Metric, 'config' | 'settings'> & {
  config: APIMetricConfig;
  settings: APIMetricSettings;
};

export interface Metric {
  status: string;
  kind: string;
  config: MetricConfig;
  name: string;
  settings: MetricSettings;
}

export type APIMetricConfig = Omit<MetricConfig, 'isSystemMetric'> & {
  is_system_metric?: string;
};

export interface MetricConfig {
  qos: number;
  isSystemMetric?: string;
}

export type APIMetricSettings = Omit<MetricSettings, 'collectCpuTime' | 'reportActive'> & {
  collect_cpu_time?: boolean;
  report_active?: boolean;
};

export interface MetricSettings {
  interval: string;
  totalcpu?: boolean;
  collectCpuTime?: boolean;
  reportActive?: boolean;
  percpu?: boolean;
}

export type APIStatusTopic = Omit<StatusTopic, 'isDirectory'> & {
  is_directory: string;
};

export interface StatusTopic {
  isDirectory: string;
  name: string;
}

export type APISharedUrl = Omit<SharedUrl, 'urlUuid' | 'expiryTime' | 'createdAt'> & {
  url_uuid: string;
  expiry_time: string;
  created_at: string;
};

export interface SharedUrl {
  id: number;
  urlUuid: string;
  expiryTime: string;
  creator: string;
  createdAt: string;
}

export type APILogDetail = Omit<
  LogDetail,
  | 'createdAt'
  | 'errorMessage'
  | 'requestUuid'
  | 'updatedAt'
  | 'sharedUrls'
  | 'totalSize'
  | 'deviceId'
> & {
  created_at: string;
  error_message: string;
  request_uuid: string;
  shared_urls?: APISharedUrl[];
  updated_at: string;
  total_size: number;
  device_id: string;
};

export interface LogDetail {
  createdAt: string;
  creator: string;
  errorMessage: string;
  filename: string;
  requestUuid: string;
  sharedUrls?: SharedUrl[];
  status: string;
  updatedAt: string;
  totalSize: number;
  deviceId: string;
}

export type APIAddDevice = Omit<AddDevice, 'configVariables'> & {
  config_variables: Partial<Record<ConfigVars, string>>;
};

export interface AddDevice {
  name: string;
  description: string;
  configVariables: Partial<Record<ConfigVars, string>>;
}

export interface APIAddDeviceResponse {
  data: string;
  script_command: string;
  device_id: string;
}

export interface AddDeviceResponse {
  token: string;
  scriptCommand: string;
  deviceId: string;
}

export type APITopic = Omit<Topic, 'config'> & {
  config: APITopicConfig;
};

export interface Topic {
  config: TopicConfig;
  name: string;
}

export interface APIReqSubscribeTopic {
  kind: string;
  topics: APITopic[];
}

export interface ReqSubscribeTopic {
  type: keyof TopicStateData;
  qos: number;
  name: string;
  tags: {
    fieldOverrides: string[];
    whiteListTags: string[];
  };
}

export interface APIResTopics {
  topics: APITopics;
  master_up: boolean;
}

export interface ResTopics {
  topics: Topics;
  masterUp: boolean;
}

export interface APITopics {
  Unsubscribed: string[];
  Subscribed: APISubscribed;
}

export interface Topics {
  unsubscribed: string[];
  subscribed: Subscribed;
}

export interface APISubscribed {
  metric: APITopic[];
  log: APITopic[];
}

export interface Subscribed {
  metric: Topic[];
  log: Topic[];
}

export interface APITopicConfig {
  qos: number;
  whitelist_field: string[];
  whitelist_tag: string[];
}

export interface TopicConfig {
  qos: number;
  whitelistFields: string[];
  whitelistTags: string[];
}

export interface TopicData {
  name: string;
  kind: keyof TopicStateData;
  qos?: number;
  subsStatus: SubscribeStatus;
  apiStatus: APICallStatus;
}

export interface TopicStateData {
  log: TopicStateConfig;
  metric: TopicStateConfig;
}

export interface TopicStateConfig {
  status: SubscribeStatus;
  qos?: number;
}

export interface FilterDevicesReq {
  operator: string;
  specs: Specs;
}

export interface Specs {
  operator: string;
  args: Arg[];
}

export interface Arg {
  operator: string;
  args: string[];
}

export interface DeviceCondensed {
  id: number;
  name: string;
  status: string;
  uuid: string;
}

export interface UpdateStatus {
  deviceId: string;
  status: string;
}

export interface DetailsError {
  deviceId: string;
  error: RIOError;
}

export interface ReqDownloadDeviceLog {
  deviceId: string;
  uuid: string;
}

export interface APIDeviceLogUrl {
  signed_url: string;
}

export interface DeviceLogUrl {
  signedUrl: string;
}

export interface APIResRosbagBlobs {
  data: APIRosbagBlob[];
  links: ListLinks;
  meta: ListMeta;
}

export type APIRosbagBlob = Omit<
  RosbagBlob,
  'createdAt' | 'deleteAt' | 'id' | 'updatedAt' | 'job'
> & {
  CreatedAt: string;
  DeletedAt: string | null;
  ID: number;
  UpdatedAt: string;
  job: APIRosbagJob;
};

export interface RosbagBlob {
  createdAt: string;
  deletedAt: string | null;
  id: number;
  updatedAt: string;
  blobRefID: number;
  componentType: string;
  creator: string;
  deviceID: string;
  duration: number;
  endTime: number;
  errorMessage: string;
  filename: string;
  guid: string;
  indexed: boolean | null;
  job: RosbagJob;
  jobID: number;
  project: string;
  size: number;
  startTime: number;
  status: string;
}

export type APIRosbagJob = Omit<RosbagJob, 'createdAt' | 'deleteAt' | 'id' | 'updatedAt'> & {
  CreatedAt: string;
  DeletedAt: string | null;
  ID: number;
  UpdatedAt: string;
  status: string;
};

export interface RosbagJob {
  createdAt: string;
  deletedAt: string | null;
  id: number;
  updatedAt: string;
  deploymentID: string;
  deploymentName: string;
  guid: string;
  name: string;
}

export interface ListLinks {
  first: number;
  last: number;
  next: number;
  prev: null;
}

export interface ListMeta {
  count: number;
  total: number;
}

export interface RecordOptions {
  allTopics?: boolean;
  chunkSize: number;
  compression: string;
  maxMessageCount?: number;
  maxSplitSize: number;
  maxSplits: number;
  node?: string;
  prefix: string;
  topicExcludeRegex?: string;
  topicIncludeRegex?: string[];
  topics: string[];
  maxSplitDuration?: number;
}

export enum UploadType {
  CONTINUOUS = 'Continuous',
  ON_DEMAND = 'OnDemand',
  ON_STOP = 'OnStop',
}

export interface UploadOptions {
  uploadType: UploadType;
  maxUploadRate: number;
  purgeAfter?: boolean;
}

export interface Info {
  bagVersion: string;
  compressedSize: number;
  compression: string;
  duration: number;
  endTime: number;
  indexed: boolean;
  messageCount: number;
  messageTypes: MessageType[];
  size: number;
  startTime: number;
  topics: RosbagTopic[];
  uncompressedSize: number;
}

export interface MessageType {
  md5: string;
  type: string;
}

export interface RosbagTopic {
  frequency: number;
  messageCount: number;
  messageType: string;
  name: string;
}

export interface ReqDeviceLogList {
  deviceId: string;
  filter?: Record<string, string>;
  page: { number: number };
  sort: string;
}

export type APIDeviceLog = Omit<
  DeviceLog,
  'createdAt' | 'errorMessage' | 'requestUuid' | 'updatedAt' | 'totalSize'
> & {
  created_at: string;
  error_message: string;
  request_uuid: string;
  updated_at: string;
  total_size: number;
};

export interface DeviceLog {
  createdAt: string;
  creator: string;
  errorMessage: string;
  filename: string;
  metadata: Record<string, string>;
  requestUuid: string;
  totalSize: number;
  status: string;
  updatedAt: string;
}

export interface ResDeviceLog {
  data: DeviceLog[];
  meta: {
    count: number;
    total: number;
  };
}

export interface ReqUploadLog {
  device_path: string;
  file_name: string;
  max_upload_rate: number;
  metadata: Record<string, string>;
  override: boolean;
  purge_after: boolean;
}

export interface ReqCmd {
  bg: boolean;
  cmd: string;
  cwd: string;
  device_ids: string[];
  env: Env;
  runas: string;
  shell: string;
}

export interface Env {
  additionalProp1: string;
  additionalProp2: string;
  additionalProp3: string;
}

export type APIMetricErrorLog = Omit<MetricErrorLog, 'results' | 'currentPage' | 'pageSize'> & {
  results: APIResult[];
  current_page: number;
  page_size: number;
};

export type APIResult = Omit<Result, 'statementId'> & {
  statement_id: number;
};

export interface ReqMetricLog {
  date: number[];
  search: string[];
  pageSize: number;
  currentPage: number;
}

export interface MetricErrorLog {
  results: Result[];
  count: number;
  currentPage: number;
  pageSize: number;
}

export interface FormattedMetricItem {
  key: string;
  timestamp: string;
  message: string;
}
export interface FormattedMetricErrorLog {
  list: FormattedMetricItem[];
  count: number;
}

export interface Result {
  statementId: number;
  series: Series[];
}

export interface Series {
  name: string;
  columns: string[];
  values: Array<string[]>;
}

export type TerminalData = Record<string, string[]>;

export enum SubscribeStatus {
  SUBSCRIBED = 'subscribed',
  SUBSCRIBING = 'subscribing',
  UNSUBSCRIBED = 'unsubscribed',
  UNSUBSCRIBING = 'unsubscribing',
}

export enum Runtimes {
  DEVICE = 'device',
  CLOUD = 'cloud',
}

export enum DeviceRuntimes {
  PRE_INSTALLED = 'preinstalled',
  DOCKER_COMPOSE = 'dockercompose',
}

export enum ConfigVars {
  ROS_DISTRO = 'ros_distro',
  ROS_WORKSPACE = 'ros_workspace',
  RUNTIME = 'runtime',
  ROSBAG_MOUNT_PATH = 'rosbag_mount_path',
  DOCKER = 'runtime_docker',
  PRE_INSTALLED = 'runtime_preinstalled',
}

export enum MetricsSubscriptionStatuses {
  SUBSCRIBED = 'subscribed',
  UNSUBSCRIBED = 'unsubscribed',
  SUBSCRIBING = 'subscribing',
  UNSUBSCRIBING = 'unsubscribing',
}

export interface BaseDevicesAction {
  type: ActionTypes;
}

export type DevicesAction<P = undefined> = P extends undefined
  ? BaseDevicesAction
  : BaseDevicesAction & { payload: P };

export type DevicesActionPayload =
  | boolean
  | number
  | string
  | ReqCmd
  | Device[]
  | DeviceDetails
  | Label
  | UpdateStatus
  | DetailsError
  | TopicData
  | Record<string, TopicStateData>
  | TerminalData
  | RIOError
  | ResDeviceLog
  | APICallStatus
  | RIOError
  | AddDeviceResponse;

export interface DevicesState {
  selectedDeviceID: string | null;
  list: Device[];
  listApiStatus: APICallStatus;
  error: RIOError | null;
  addDeviceApiStatus: APICallStatus;
  modalOpen: boolean;
  addDeviceToken: string;
  scriptCommand: string;
  runningDeploymentsPayload: DetailsError | null;

  topicsApiStatus: APICallStatus;
  topics: Record<string, TopicStateData>;
  masterUp: boolean;

  detailsApiStatus: APICallStatus;
  deviceId: string;
  deviceDetails: DeviceDetails;
  uname: string;
  unameFailed: boolean;
  addLabelKey: string;
  addLabelValue: string;
  addLabelPending: boolean;
  sshSocket: null;
  nameEditMode: boolean;
  editedName: string;
  descriptionEditMode: boolean;
  editedDescription: string;

  terminalApiStatus: APICallStatus;
  terminalData: string[];

  logs: DeviceLog[];
  logsTotal: number;
  logsApiStatus: APICallStatus;
}

export enum DeviceSortOption {
  NAME = 'name',
  LAST_ONLINE = 'lastOnline',
  REGISTRATION_TIME = 'registrationTime',
  STATUS = 'status',
}
