export const capitalizeWord = (word: string): string =>
  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

export const capitalize = (str: string): string => str.split(' ').map(capitalizeWord).join(' ');

export const escape = (str: string): string => {
  const map: Record<string, string> = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
  };
  return str.replace(/[&<>"']/g, (m) => map[m]);
};
export const trimBackSlashEnd = (str = ''): string => str.trim().replace(/\/+$/g, '');

export const trimBackSlashStart = (str = ''): string => str.trim().replace(/^\/+/g, '');

export const trimBackSlash = (str = ''): string => str.trim().replace(/^\/+|\/+$/g, '');

export const trimStart = (str = '', chars = ''): string =>
  str.startsWith(chars) ? str.slice(chars.length) : str;

export const intersection = <T>(arr: T[], ...args: T[][]): T[] =>
  arr.filter((item) => args.every((arg) => arg.includes(item)));

export const replaceEmptyStringWithUndefined = <T>(data: T): T =>
  Object.entries(data).reduce(
    (accData, [key, value]) =>
      value === '' ? { ...accData, [key]: undefined } : { ...accData, [key]: value },
    {} as T,
  );

export const addEllipses = (text: string, maxLength = 25): string => {
  if (text.length <= maxLength) {
    return text;
  }
  return `${text.slice(0, maxLength)}...`;
};
