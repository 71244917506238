enum CatalogActionTypes {
  PACKAGES_ADD_MANIFEST_BACKUP = 'PACKAGES_ADD_MANIFEST_BACKUP',
  PACKAGES_GET_PENDING = 'PACKAGES_GET_PENDING',
  PACKAGES_GET_FULFILLED = 'PACKAGES_GET_FULFILLED',
  PACKAGES_GET_REJECTED = 'PACKAGES_GET_REJECTED',
  PACKAGES_DELETE_SUCCESSFUL = 'PACKAGES_DELETE_SUCCESSFUL',
  PACKAGES_TOGGLE_DEPLOY_MODAL = 'PACKAGES_TOGGLE_DEPLOY_MODAL',
  PACKAGE_DETAILS_GET_PENDING = 'PACKAGE_DETAILS_GET_PENDING',
  PACKAGE_DETAILS_GET_FULFILLED = 'PACKAGE_DETAILS_GET_FULFILLED',
  PACKAGE_DETAILS_GET_REJECTED = 'PACKAGE_DETAILS_GET_REJECTED',
  PACKAGE_DETAILS_PLAN_EXPAND_TOGGLE = 'PACKAGE_DETAILS_PLAN_EXPAND_TOGGLE',
  PACKAGE_DETAILS_CLEAR = 'PACKAGE_DETAILS_CLEAR',
  PACKAGE_DEPLOYMENTS_GET_PENDING = 'PACKAGE_DEPLOYMENTS_GET_PENDING',
  PACKAGE_DEPLOYMENTS_GET_FULFILLED = 'PACKAGE_DEPLOYMENTS_GET_FULFILLED',
  PACKAGE_PAGE_SET_ID = 'PACKAGE_PAGE_SET_ID',
  RESET_DETAILS_API_STATUS = 'RESET_DETAILS_API_STATUS',
}

export default CatalogActionTypes;
