export enum DeploymentActionTypes {
  DEPLOYMENT_PAGE_SET_ID = 'DEPLOYMENT_PAGE_SET_ID',

  DEPLOYMENTS_GET_PENDING = 'DEPLOYMENTS_GET_PENDING',
  DEPLOYMENTS_GET_FULFILLED = 'DEPLOYMENTS_GET_FULFILLED',
  DEPLOYMENTS_GET_REJECTED = 'DEPLOYMENTS_GET_REJECTED',
  UPDATE_DEPLOYMENTS_LIST = 'UPDATE_DEPLOYMENTS_LIST',

  DEPLOYMENT_DETAILS_GET_PENDING = 'DEPLOYMENT_DETAILS_GET_PENDING',
  DEPLOYMENT_DETAILS_GET_FULFILLED = 'DEPLOYMENT_DETAILS_GET_FULFILLED',
  DEPLOYMENT_DETAILS_GET_REJECTED = 'DEPLOYMENT_DETAILS_GET_REJECTED',

  DEPLOYMENT_DELETE_PENDING = 'DEPLOYMENT_DELETE_PENDING',
  DEPLOYMENT_DELETE_FULFILLED = 'DEPLOYMENT_DELETE_FULFILLED',
  DEPLOYMENT_DELETE_REJECTED = 'DEPLOYMENT_DELETE_REJECTED',

  UPDATE_DEPLOYMENT = 'UPDATE_DEPLOYMENT',

  SET_COLLECT_ROSBAGS_STATUS = 'SET_COLLECT_ROSBAGS_STATUS',
  SET_UPDATE_ROSBAG_JOB_STATUS = 'SET_UPDATE_ROSBAG_JOB_STATUS',
}

export default DeploymentActionTypes;
