import {
  AddManifestBackupPayload,
  CatalogAction,
  CatalogActionPayload,
  CatalogState,
  Package,
  PackageDeployment,
  UpdatePkgDetailsPayload,
} from 'Models/packages/types';
import ActionTypes from 'RioRedux/packages/actionTypes';

import { APICallStatus, RIOError } from '../../models/common/types';

const initialState: CatalogState = {
  selectedPackageId: null,
  list: [],
  addManifestBackup: null,
  addManifestProject: null,
  details: null,
  isDeployModalOpen: false,
  packageUrl: '',
  deploymentsList: [],
  apiStatus: APICallStatus.INITIAL,
  detailsAPIStatus: APICallStatus.INITIAL,
  error: null,
};

const CatalogReducer = (
  state: CatalogState = initialState,
  action: CatalogAction<CatalogActionPayload>,
): CatalogState => {
  switch (action.type) {
    case ActionTypes.PACKAGE_PAGE_SET_ID: {
      const payload = action.payload as string;
      if (state.selectedPackageId !== payload) {
        return {
          ...state,
          selectedPackageId: payload,
        };
      }

      return state;
    }

    case ActionTypes.PACKAGES_ADD_MANIFEST_BACKUP: {
      const payload = action.payload as AddManifestBackupPayload;

      return {
        ...state,
        addManifestBackup: payload.manifest,
        addManifestProject: payload.selectedProject,
      };
    }

    case ActionTypes.PACKAGES_GET_PENDING:
      return {
        ...state,
        apiStatus: APICallStatus.LOADING,
        error: null,
      };

    case ActionTypes.PACKAGES_GET_FULFILLED:
      return {
        ...state,
        apiStatus: APICallStatus.LOADED,
        list: action.payload as Package[],
        error: null,
      };

    case ActionTypes.PACKAGES_GET_REJECTED:
      return {
        ...state,
        list: [],
        apiStatus: APICallStatus.ERROR,
        error: action.payload as RIOError,
      };

    case ActionTypes.PACKAGES_DELETE_SUCCESSFUL: {
      const payload = action.payload as string;

      return {
        ...state,
        apiStatus: APICallStatus.LOADED,
        list: state.list.filter((pkg) => pkg.id !== payload),
      };
    }

    case ActionTypes.PACKAGE_DETAILS_GET_PENDING:
      return {
        ...state,
        detailsAPIStatus: APICallStatus.LOADING,
        error: null,
      };

    case ActionTypes.PACKAGE_DETAILS_GET_FULFILLED: {
      const payload = action.payload as UpdatePkgDetailsPayload;
      return {
        ...state,
        detailsAPIStatus: APICallStatus.LOADED,
        error: null,
        details: payload.packageInfo,
        packageUrl: payload.packageUrl,
      };
    }

    case ActionTypes.PACKAGE_DETAILS_GET_REJECTED:
      return {
        ...state,
        detailsAPIStatus: APICallStatus.ERROR,
        error: action.payload as RIOError,
        details: null,
      };

    case ActionTypes.PACKAGE_DETAILS_PLAN_EXPAND_TOGGLE: {
      const selectedPlanId = action.payload as string;

      const plans = [...(state.details?.plans ?? [])];
      const idxToUpdate = plans.findIndex((p) => p.planId === selectedPlanId);
      const selectedPlan = plans[idxToUpdate];
      if (selectedPlan) {
        selectedPlan.isExpanded = !selectedPlan.isExpanded;
        plans[idxToUpdate] = selectedPlan;
      }

      return {
        ...state,
        details: state.details && {
          ...state.details,
          plans,
        },
      };
    }

    case ActionTypes.PACKAGES_TOGGLE_DEPLOY_MODAL: {
      return {
        ...state,
        isDeployModalOpen: !state.isDeployModalOpen,
      };
    }

    case ActionTypes.PACKAGE_DETAILS_CLEAR:
      return initialState;

    case ActionTypes.PACKAGE_DEPLOYMENTS_GET_PENDING:
      return {
        ...state,
        apiStatus: APICallStatus.LOADING,
      };

    case ActionTypes.PACKAGE_DEPLOYMENTS_GET_FULFILLED:
      return {
        ...state,
        apiStatus: APICallStatus.LOADED,
        deploymentsList: action.payload as PackageDeployment[],
      };

    case ActionTypes.RESET_DETAILS_API_STATUS:
      return {
        ...state,
        detailsAPIStatus: APICallStatus.INITIAL,
      };

    default:
      return state;
  }
};

export default CatalogReducer;
