import React, { ReactNode } from 'react';
import { TablePaginationConfig } from 'antd/lib/table';
import { Key } from 'antd/lib/table/interface';
import { AxiosError } from 'axios';
import { RouterAction, RouterState } from 'connected-react-router';
import { LocationState } from 'history';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { BillingActionPayload, BillingState } from 'Models/billing/types';
import { DeploymentActionPayload, DeploymentState } from 'Models/deployments/types';
import { DevicesActionPayload, DevicesState } from 'Models/devices/types';
import { MarketplaceActionPayload, MarketplaceState } from 'Models/marketplace/types';
import {
  NotificationPayload,
  NotificationState,
  NotificationType,
} from 'Models/notifications/types';
import {
  APIOrganization,
  OrgActionPayload,
  Organization,
  OrgState,
} from 'Models/organization/types';
import { CatalogActionPayload, CatalogState } from 'Models/packages/types';
import {
  Project,
  ProjectsActionPayload,
  ProjectsState,
  ProjectUser,
  UserAPIProject,
  UserProject,
} from 'Models/projects/types';
import { SecretActionPayload, SecretState } from 'Models/secrets/types';
import { StaticRoutesPayload, StaticRouteState } from 'Models/staticRoutes/types';
import { UsageActionPayload, UsageState } from 'Models/usage/types';
import BillingActionTypes from 'RioRedux/billing/actionTypes';
import CLIManifestActionTypes from 'RioRedux/cliManifest/actionTypes';
import CommonActionTypes from 'RioRedux/common/actionTypes';
import ConfigurationActionTypes from 'RioRedux/configurations/actionTypes';
import DeploymentActionTypes from 'RioRedux/deployments/actionTypes';
import DevicesActionTypes from 'RioRedux/devices/actionTypes';
import DisksActionTypes from 'RioRedux/disks/actionTypes';
import MarketplaceActionTypes from 'RioRedux/marketplace/actionTypes';
import MetricsActionTypes from 'RioRedux/metrics/actionTypes';
import NotificationActionTypes from 'RioRedux/notifications/actionTypes';
import OrgActionTypes from 'RioRedux/organization/actionTypes';
import PackagesActionTypes from 'RioRedux/packages/actionTypes';
import ProjectsActionTypes from 'RioRedux/projects/actionTypes';
import SecretsActionTypes from 'RioRedux/secrets/actionTypes';
import StaticRouteActionTypes from 'RioRedux/staticRoutes/actionTypes';
import UsageActionTypes from 'RioRedux/usage/actionTypes';
import { USERROLE } from 'Shared/types';

import { CLIManifestActionPayload } from '../cliManifest/types';
import { ConfigurationActionPayload, ConfigurationState } from '../configurations/types';
import { DisksActionPayload, DisksState } from '../disks/types';
import { MetricsActionPayload, MetricsState } from '../metrics/types';

// TODO : these doesn't belong here move it to appropriate place

export interface AppState {
  readonly router: RouterState<LocationState>;
  readonly common: CommonState;
  readonly configurations: ConfigurationState;
  readonly organization: OrgState;
  readonly projects: ProjectsState;
  readonly packages: CatalogState;
  readonly deployments: DeploymentState;
  readonly devices: DevicesState;
  readonly disks: DisksState;
  readonly secrets: SecretState;
  readonly plansAndUsageDetails: BillingState;
  readonly marketplace: MarketplaceState;
  readonly usage: UsageState;
  readonly persistentNotifications: PersistentNotificationState;
  readonly staticRoutes: StaticRouteState;
  readonly metrics: MetricsState;
  readonly notification: NotificationState;
}

export type AppActionTypes =
  | CommonActionTypes
  | ConfigurationActionTypes
  | DevicesActionTypes
  | DisksActionTypes
  | BillingActionTypes
  | PackagesActionTypes
  | UsageActionTypes
  | ProjectsActionTypes
  | DeploymentActionTypes
  | OrgActionTypes
  | SecretsActionTypes
  | MarketplaceActionTypes
  | StaticRouteActionTypes
  | NotificationActionTypes
  | MetricsActionTypes
  | CLIManifestActionTypes;

export type AppActionPayloads =
  | CommonActionPayload
  | ConfigurationActionPayload
  | DevicesActionPayload
  | DisksActionPayload
  | BillingActionPayload
  | CatalogActionPayload
  | UsageActionPayload
  | ProjectsActionPayload
  | DeploymentActionPayload
  | OrgActionPayload
  | SecretActionPayload
  | MarketplaceActionPayload
  | StaticRoutesPayload
  | NotificationPayload
  | MetricsActionPayload
  | CLIManifestActionPayload;

export type AppAction =
  | {
      type: AppActionTypes;
      payload?: AppActionPayloads;
    }
  | RouterAction<LocationState>;

export type RIOThunkAction = ThunkAction<void, AppState, unknown, AppAction>;
export type RIOThunkDispatch = ThunkDispatch<AppState, unknown, AppAction>;

export interface CommonState {
  userToken: string;
  authToken: string;
  authTokenAPIStatus: APICallStatus;
  authTokenAPIError: string;
  userType: UserType;
  username: string;
  userDetails: User;
  userMap: UserMap;
  selectedProjectGUID: string;
  selectedOrgGUID: string;
  featuresEnabled: Feature[];
  isNewUser: boolean;
  apiStatus: APICallStatus;
  errorMessage: string;
  showBanner: boolean;
  updateUserAPIStatus: APICallStatus;
}

export interface Notification {
  action: string;
  targetId: string;
  type: string;
  time: Date;
  showTime: boolean;
  showType: boolean;
  content: Content;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: any[]; // TODO: Fix type.
}

export interface Content {
  heading: string;
  matter: React.FC;
}

export interface PersistentNotificationState {
  notifications: Record<string, Notification>;
}

export interface Feature {
  name: string;
}

export enum UserType {
  PENDING = 'pending',
}

export type UserMap = Record<string, ProjectUser>;

export interface UserStateOrgMap {
  organizationGUID: string;
  userState: EntityState;
}

export interface User {
  guid: string;
  firstName: string;
  lastName: string;
  emailID: string;
  state: EntityState;
  isAdmin?: boolean;
  roleInOrganization?: UserRoles;
  projects?: UserProject[];
  organization?: Organization;
  organizations?: Organization[];
  featuresEnabled?: Feature[];
  userStateInOrgs: UserStateOrgMap[];
}

export type UserWithRole = User & { role: USERROLE };

export interface UserUpdateProps {
  firstName?: string;
  lastname?: string;
  email?: string;
  password?: string;
}

export enum EntityState {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  INVITED = 'INVITED',
  REGISTERED = 'REGISTERED',
  SUSPENDED = 'SUSPENDED',
}

export enum UserRoles {
  ADMIN = 'Admin',
}

export type CommonActionPayload =
  | User
  | UserMap
  | string
  | boolean
  | Project[]
  | APICallStatus
  | CopyToClipboardPayload
  | User[]
  | UpdateProjectsAndSelectedProjectPayload;

interface BaseCommonAction {
  type: CommonActionTypes;
}

export type CommonAction<P = undefined> = P extends undefined
  ? BaseCommonAction
  : BaseCommonAction & { payload: P };

export enum APICallStatus {
  INITIAL,
  LOADING,
  LOADED,
  ERROR,
}

export interface RIOError {
  status?: number;
  message: string;
}

export interface APIErrorData {
  error: string;
}

export type APIError = AxiosError<APIErrorData>;

export interface PlanMetaData {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  organizationID: number;
  planID: number;
  trialPeriodEndDate: string | null;
  plan: Plan;
  pricingInfo: PricingInfo[];
}

export interface Plan {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  name: string;
  metadata: string;
  planType: string;
  isActive: boolean;
  displayName: string;
}

export interface PricingInfo {
  chargeID: string;
  chargeNumber: string;
  currency: string;
  includedUnits: number;
  overagePrice: number;
  price: number;
  uom: string;
}

export interface APIPlanMetaData {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  organizationID: number;
  planID: number;
  trialPeriodEndDate: string | null;
  plan: APIPlan;
  pricingInfo: PricingInfo[];
}

export interface APIPlan {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  name: string;
  metadata: string;
  planType: string;
  isActive: boolean;
  displayName: string;
}

export interface APIUser {
  guid: string;
  firstName: string;
  lastName: string;
  emailID: string;
  state: EntityState;
  roleInOrganization: UserRoles;
  projects?: UserAPIProject[];
  featuresEnabled: Feature[];
  organization?: APIOrganization;
  organizations?: APIOrganization[];
  userStateInOrgs: UserStateOrgMap[];
}

export type UserAPIReturn = User & { featuresEnabled: Feature[] };

export enum RunTime {
  DEVICE = 'device',
  CLOUD = 'cloud',
}
export interface APIHeaders {
  project?: string;
}

export interface Limits {
  cpu: number;
  memory: number;
}

export type FileReqHeaders = {
  'Content-Type'?: string;
  'X-Rapyuta-Params-Version'?: number;
  Checksum?: string;
};

export type IOHeaders = {
  Authorization: string;
  project?: string;
  'retry-after'?: boolean;
} & FileReqHeaders;

export enum TrackTypes {
  SUCCESS = 'success',
  ERROR = 'error',
}
export type TrackFnParam = {
  category: string;
  event: string;
  startDate: Date;
};
export type TableFilters = Record<string, (boolean | Key)[] | null>;

export interface TableQuery {
  pagination: TablePaginationConfig;
  filters: TableFilters;
  sort: string;
}

export interface KeyValue<T> {
  key: string;
  value: T;
}

export enum SortDirection {
  ASCEND = 'ascend',
  DESCEND = 'descend',
}
export enum BannerTypes {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
  QUESTION = 'question',
}

export interface BannerConfigItem {
  message: string;
  content?: string[];
  priority: number;
  type: BannerTypes;
  showAfter?: string;
  hideAfter?: string;
  localStorageKey: string;
  title: string;
  visible: boolean;
}

export interface SelectOption {
  label: string;
  value: string;
  key?: string;
  children?: string;
}
export interface RjsfError {
  name: string;
  property: string;
  message: string;
  stack: string;
}

export interface RsjfObject<T> {
  formData: T;
}

export type RjsfFieldValidation = {
  __errors: string[];
  addError: (message: string) => void;
} & {
  [key: string]: RjsfFieldValidation;
};

export enum ROSVersion {
  FOXY = 'foxy',
  NOETIC = 'noetic',
  MELODIC = 'melodic',
  KINETIC = 'kinetic',
}

export interface CopyToClipboardPayload {
  content: string;
  title: string;
  showNotification: boolean;
}

export interface RosDetail {
  compression: string;
  name: string;
  qos: string;
  scoped: boolean;
  targeted: boolean;
}

export type TopicDetail = RosDetail & { topicName: string };
export type ServiceDetail = RosDetail & { serviceName: string; timeout: boolean };
export type ActionDetail = RosDetail & { actionName: string };

export enum ButtonColors {
  primary = 'primary',
  secondary = 'secondary',
}

export enum ButtonWidth {
  medium = 'medium',
  large = 'large',
  full = 'full',
}

export interface ProfileFormData {
  firstName: string;
  lastName: string;
  emailID: string;
}

export interface EmailChangeFormData {
  emailID?: string;
  password?: string;
}

export interface LocalNotificationPayload {
  message: string;
  description?: string;
  type: NotificationType;
}

export type LocalNotification = {
  id: string;
} & LocalNotificationPayload;

export interface AuthTokenFormData {
  email: string;
  password: string;
}

export interface HyperLinkInfo {
  to: string;
  icon: ReactNode;
  text: string;
}
export interface ErrorInfo {
  description: string;
  actions: string[];
}

export type ErrorMap = Record<string, ErrorInfo>;

export interface UpdateProjectsAndSelectedProjectPayload {
  projects: Project[];
  selectedProjectGUID: string;
}
